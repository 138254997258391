import BrowserPersistence from './browser_persistence';
const storage = new BrowserPersistence();
const token = storage.getItem('token');
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("x-api-key", "atTr7IGkykUsxp9SfqhA9TkQiFNzOjeTAMKXkRSKi");
// myHeaders.append("Authorization", token ?  `Token ${token}` : null);


const postRequest = (url, params, type) => {
	let raw = JSON.stringify(params);
	let requestOptions = {
	  method: type || 'POST',
	  headers: myHeaders,
	  body: raw,
	  redirect: 'follow'
	}
	return fetch(url, requestOptions)
}

export default postRequest;
