import React, {Fragment, useContext} from 'react';
import { Link } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Style from './appDrawer.module.scss';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import {BrowserPersistence} from '../../index'
import isDeepEqual from 'fast-deep-equal/react';
// import {BrowserPersistence} from '@react-projects/shared';

const storage = new BrowserPersistence();

const DrawerList = (props) => {
    const {drawerConfig, appContext} = props;
    const { routes, logout, sideBarActiveLink } =  useContext(appContext);
     const [open, setOpen] = React.useState(false);
     const [openTitle, setOpenTitle] = React.useState("");
    const handleOpen = (title) => {
        setOpenTitle(title);
        setOpen(!open)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const access = storage.getItem('access'); 
    const username = storage.getItem('username');
    

    return(
        <Fragment>
            <div className={Style.app_drawer_wrapper}>
                <div className={Style.user_details_wrapper}>
                    <div className={Style.user_details}>
                        <div className={Style.user_image}>
                            <img src="https://sale-banners-dtkrt-2020.s3.ap-south-1.amazonaws.com/user.png" alt="user" />
                        </div>
                        <div className={Style.user_info}>
                            {username && 
                                <p className={Style.user_name}>{username}</p>
                            }
                        </div>
                    </div>
                </div>
                <List>
                    {
                        access?.length > 0 ?
                        <>
                            {drawerConfig.map((data, index) => (
                                <Fragment key={`${data.title}${index}-key`}>
                                {
                                    data?.permission?.some((val) => access.indexOf(val) !== -1) ?
                                    <>
                                        {
                                            <Link
                                                to={routes[data.path] ? routes[data.path] : "#"}
                                                onClick={()=>data.title==='Logout' ? logout() :null}
                                            >
                                                <ListItem 
                                                    style={{background:sideBarActiveLink===data.title && '#6c757d'}} 
                                                    onClick={() => {data.children ? handleOpen(data.title) : handleClose()}} 
                                                    className={Style.list}
                                                >
                                                    <ListItemIcon className={sideBarActiveLink===data.title ? Style.active_color : Style.icon_color} >
                                                        {data.leftIcon}
                                                    </ListItemIcon>
                                                    <ListItemText className={sideBarActiveLink===data.title ? Style.active_color : Style.text_color} primary={data.title} />
                                                    {data.children &&  ((open && data.title === openTitle) ? <ExpandLess className={Style.icon_color} /> : <ExpandMore className={Style.icon_color} />)}
                                                </ListItem>
                                            </Link>
                                        }
                                        {
                                            data.children &&
                                                <Collapse in={open && data.title === openTitle} timeout="auto" unmountOnExit>
                                                    {
                                                        data.children.map((item,index) => (
                                                            <Fragment key={`${item.title}${index}-key`}>
                                                                {
                                                                    routes[item.path] ? 
                                                                    <Link to={routes[item.path]} >
                                                                        <ListItem
                                                                            style={{background:sideBarActiveLink===item.title && '#6c757d'}} 
                                                                            className={Style.list}
                                                                        >
                                                                            <ListItemText className={sideBarActiveLink===item.title ? Style.active_child_color : Style.child} primary={item.title}   />
                                                                        </ListItem>
                                                                    </Link> :
                                                                    <a href={getRoute(item.title)} target="_blank">
                                                                        <ListItem 
                                                                            style={{background:sideBarActiveLink===item.title && '#6c757d'}}
                                                                            className={Style.list}
                                                                        >
                                                                            <ListItemText className={sideBarActiveLink===item.title ? Style.active_child_color : Style.child} primary={item.title}   />
                                                                        </ListItem>
                                                                    </a>

                                                                }
                                                            </Fragment>

                                                        ))
                                                    }
                                                </Collapse>
                                        }
                                    </> :  null
                                
                                }

                                </Fragment>

                            ))}
                        </> : null
                    }

                </List>
            </div>
        </Fragment>
    )
}

export default DrawerList;



const getRoute = (title) => {
    if(title === 'Older Version'){
        return 'http://zxcvbnm4378456.dentalkart.com/customerCare/reimbursement';
    }
    return '#'
}
