import React, {useContext} from "react";
import { Link } from "react-router-dom";
import Style from './header.module.scss';
import {AppContext} from '@react-projects/shared';

function Header() {
    const { routes } =  useContext(AppContext);

  return (
    <div className={Style.desktop_header}>
        <div className={Style.header}>
          <Link
            to={routes.GO_TO_HOMEPAGE}
            className={Style.dentalkart_logo}
          >
            <img
              src="https://dentalkart-media.s3.ap-south-1.amazonaws.com/updatedLogo/Logo.png"
              alt="Dentalkart"
            />
          </Link>
        </div>
    </div>
  );
}

export default Header;
