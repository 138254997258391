// import BrowserPersistence from '@helpers/browser_persistence';
// const storage = new BrowserPersistence();
//
// const token = storage.getItem('token');
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");




const putRequest = (url, params) => {
	let raw = JSON.stringify(params);
	console.log(raw);

	let requestOptions = {
	  method: 'PUT',
	  headers: myHeaders,
	  body: raw,
	  redirect: 'follow'
	};
	// let headers = {"Content-Type": "application/json"};
    // if (token) {
    //   headers["Authorization"] = `Token ${token}`;
    // }
	return fetch(url, requestOptions)
}

export default putRequest;
