import BrowserPersistence from './browser_persistence';
const storage = new BrowserPersistence();

const token = storage.getItem('token') || null;

const getRequest = (url) => {
    let headers = {"Content-Type": "application/json"};
    // if (token) {
    //   headers["Authorization"] = `Token ${token}`;
    // }
	return fetch(url, {
		method: 'GET',
        headers: new Headers({...headers}),
	});
}

export default getRequest;
