
import {
    HomePage,
    ReturnScreen,
    ReturnItemsRequest,
    ReturnApproved,
    ReturnConfiguration,
    ApprovedProducts,
    ReturnCreate,
    SaleTemplate,
    SaleComponent,
    CallCustomer,
    Reimbursement,
    ReimbursementConfig,
    CallConfig,
    CreateForm,
    FormViewComponent,
    FormResponse,
    FormResponseById,
    WhatsappApiScreen,
    Orders,
    AuthScreen,
    CancelOrderScreen,
    CancelRequestScreen,
    CancelConfig,
    CancelReuqestItems,
    UpdateVinOrders,
    SignupScreen,
    UserlistScreen,
    RefundsList,
    RefundsPaymentList,
    RefundItem,
    RefundPaymentItem,
    CreateRefund,
    ErroScreen404
} from "@screens";


const authScreen = {
    path: "/auth/login",
    staticPath: "/auth/login",
    name: "authScreen",
    exact: true,
    component: AuthScreen,
    redirectPath: "/",
    type: "auth",
};


const homePage = {
    path: "/",
    staticPath: "/",
    name: "homepage",
    exact: true,
    component: HomePage,
    redirectPath: "/auth/login",
    type: "protected",
};

const returnItems = {
    path: "/rt-it/:id",
    staticPath: "/rt-it",
    name: "productGrid",
    exact: true,
    component: ReturnItemsRequest,
    redirectPath: "/auth/login",
    type: "protected",
};

const returnApproved = {
    path: "/rt-ap",
    staticPath: "/rt-ap",
    name: "returnApproved",
    exact: true,
    component: ReturnApproved,
    redirectPath: "/auth/login",
    type: "protected",
};

const approvedProduct = {
    path: "/ap-pr/:id",
    staticPath: "/ap-pr",
    name: "approvedProducts",
    exact: true,
    component: ApprovedProducts,
    redirectPath: "/auth/login",
    type: "protected",
};

const returnConfiguration = {
    path: "/rt-cg",
    staticPath: "/rt-cg",
    name: "returnConfiguration",
    exact: true,
    component: ReturnConfiguration,
    redirectPath: "/auth/login",
    type: "protected",
};

const returnCreate = {
    path: "/rt-cr/",
    staticPath: "/rt-cr",
    name: "returnCreate",
    exact: true,
    component: ReturnCreate,
    redirectPath: "/auth/login",
    type: "protected",
};

const returnScreen = {
    path: "/rt-rq",
    staticPath: "/rt-rq",
    name: "return",
    exact: true,
    component: ReturnScreen,
    redirectPath: "/auth/login",
    type: "protected"
};

const saleTemplate = {
    path: "/sale-template",
    staticPath: "/sale-template",
    name: "saleTemplate",
    exact: true,
    component: SaleTemplate,
    redirectPath: "/auth/login",
    type: "protected"
};

const saleComponent = {
    path:"/sale-component/:id",
    staticPath:"/sale-component",
    name:"saleComponent",
    exact:true,
    component:SaleComponent,
    redirectPath:"/auth/login",
    type:"protected"
};

const callCustomer = {
    path:"/callCustomer",
    staticPath:"/callCustomer",
    name:"callCustomer",
    exact:true,
    component:CallCustomer,
    redirectPath:"/auth/login",
    type:"protected"
};

const reimbursement = {
    path:"/reimbursement",
    staticPath:"/reimbursement",
    name:"reimbursement",
    exact:true,
    component:Reimbursement,
    redirectPath:"/auth/login",
    type:"protected"
}

const reimbursementConfig = {
    path:"/reimbursement-config",
    staticPath:"/reimbursement-config",
    name:"reimbursementConfig",
    exact:true,
    component:ReimbursementConfig,
    redirectPath:"/auth/login",
    type:"protected"
}

const callConfig = {
    path:"/callConfig",
    staticPath:"/callConfig",
    name:"callConfig",
    exact:true,
    component:CallConfig,
    redirectPath:"/auth/login",
    type:"protected"
}

const createForm = {
    path:"/createForm",
    staticPath:"/createForm",
    name:"createForm",
    exact:true,
    component:CreateForm,
    redirectPath:"/auth/login",
    type:"protected"
}

const formView = {
  path:"/form-view/:id",
  staticPath:"/form-view",
  name:"formView",
  exact:true,
  component:FormViewComponent,
  redirectPath:"/auth/login",
  type:"protected"

}

const formResponse = {
    path:"/form-response",
    staticPath:"/form-response",
    name:"formResponse",
    exact:true,
    component:FormResponse,
    redirectPath:"/auth/login",
    type:"protected"

  }


  const formResponseById = {
    path:"/form-response-id/:id",
    staticPath:"/form-response-id",
    name:"formResponseById",
    exact:true,
    component:FormResponseById,
    redirectPath:"/auth/login",
    type:"protected"

  }

  const whatsappApi = {
    path:"/whatspp-api",
    staticPath:"/whatspp-api",
    name:"whatsappApi",
    exact:true,
    component:WhatsappApiScreen,
    redirectPath:"/auth/login",
    type:"protected"

  }

  const orders = {
    path:"/orders",
    staticPath:"/orders",
    name:"Orders",
    exact:true,
    component:Orders,
    redirectPath:"/auth/login",
    type:"protected"

  }



  const cancelOrder = {
    path:"/cancel-order",
    staticPath:"/cancel-order",
    name:"cancelOrder",
    exact:true,
    component:CancelOrderScreen,
    redirectPath:"/auth/login",
    type:"protected"
  }

  const cancelRequest = {
    path:"/cancel-request",
    staticPath:"/cancel-request",
    name:"cancelRequest",
    exact:true,
    component:CancelRequestScreen,
    redirectPath:"/auth/login",
    type:"protected"

  }

  const cancelConfig = {
    path:"/cancel-config",
    staticPath:"/cancel-config",
    name:"cancelConfig",
    exact:true,
    component:CancelConfig,
    redirectPath:"/auth/login",
    type:"protected"

  }

  const cancelRequestItems = {
    path:"/cancel-requestItems/:id",
    staticPath:"/cancel-requestItems",
    name:"cancelRequestItems",
    exact:true,
    component:CancelReuqestItems,
    redirectPath:"/auth/login",
    type:"protected"

  }

  const vinculumOrders = {
    path:"/update-vin-orders",
    staticPath:"/update-vin-orders",
    name:"vinculumOrders",
    exact:true,
    component:UpdateVinOrders,
    redirectPath:"/auth/login",
    type:"protected"

  }

  const signupScreen = {
    path:"/signup-admin",
    staticPath:"/signup-admin",
    name:"signupScreen",
    exact:true,
    component:SignupScreen,
    redirectPath:"/auth/login",
    type:"protected"

  }

  const userlistScreen = {
    path:"/user-list",
    staticPath:"/user-list",
    name:"userlistScreen",
    exact:true,
    component:UserlistScreen,
    redirectPath:"/auth/login",
    type:"protected"
  }

  const refundList = {
    path:"/rf-list",
    staticPath:"/rf-list",
    name:"refundlist",
    exact:true,
    component:RefundsList,
    redirectPath:"/auth/login",
    type:"protected"
  }

  const refundPaymentList = {
    path:"/rf-pay-list",
    staticPath:"/rf-pay-list",
    name:"refundPaymentlist",
    exact:true,
    component:RefundsPaymentList,
    redirectPath:"/auth/login",
    type:"protected"
  }

  const refundItem = {
    path:"/rf-list/:id",
    staticPath:"/rf-list",
    name:"refundItem",
    exact:true,
    component:RefundItem,
    redirectPath:"/auth/login",
    type:"protected"
  }
  
  const refundPaymentItem = {
    path:"/rf-pay-list/:id",
    staticPath:"/rf-pay-list",
    name:"refundPaymentItem",
    exact:true,
    component:RefundPaymentItem,
    redirectPath:"/auth/login",
    type:"protected"
  }
  
  const createRefund = {
    path:"/rf-create",
    staticPath:"/rf-create",
    name:"createRefund",
    exact:true,
    component:CreateRefund,
    redirectPath:"/auth/login",
    type:"protected"
  }

const error404 = {
    path: "*",
    staticPath: "*",
    name: "error404",
    component: ErroScreen404
};

export const screens = [
    authScreen,
    homePage,
    returnScreen,
    returnItems,
    returnCreate,
    returnApproved,
    returnConfiguration,
    approvedProduct,
    saleTemplate,
    saleComponent,
    callCustomer,
    reimbursement,
    reimbursementConfig,
    callConfig,
    createForm,
    formView,
    formResponse,
    formResponseById,
    whatsappApi,
    cancelOrder,
    cancelRequest,
    cancelConfig,
    cancelRequestItems,
    orders,
    vinculumOrders,
    signupScreen,
    userlistScreen,
    refundList,
    refundPaymentList,
    refundItem,
    refundPaymentItem,
    createRefund,
    error404
];

export const GO_TO_HOMEPAGE = homePage.staticPath;
export const GO_TO_RETURN = returnScreen.staticPath;
export const GO_TO_RETURN_ITEM = returnItems.staticPath;
export const GO_TO_RETURN_APPROVED = returnApproved.staticPath;
export const GO_TO_RETURN_CREATE = returnCreate.staticPath;
export const GO_TO_RETURN_CONFIGURATION = returnConfiguration.staticPath;
export const GO_TO_APPROVED_PRODUCTS = approvedProduct.staticPath;
export const GO_TO_SALE_TEMPLATE = saleTemplate.staticPath;
export const GO_TO_SALE_COMPONENT = saleComponent.staticPath;
export const GO_TO_CALL_CUSTOMER = callCustomer.staticPath;
export const GO_TO_REIMBURSEMENT = reimbursement.staticPath;
export const GO_TO_REIMBURSEMENT_CONFIG = reimbursementConfig.staticPath;
export const GO_TO_CALL_CONFIG = callConfig.staticPath;
export const GO_TO_CREATE_FORM = createForm.staticPath;
export const GO_TO_FORM_VIEW = formView.staticPath;
export const GO_TO_FORM_RESPONSE = formResponse.staticPath;
export const GO_TO_FORM_RESPONSE_BY_ID = formResponseById.staticPath;
export const GO_TO_WHATSAPP_API = whatsappApi.staticPath;
export const GO_TO_ORDERS = orders.staticPath;
export const GO_TO_LOGIN_SCREEN = authScreen.staticPath;
export const GO_TO_CANCEL_ORDER = cancelOrder.staticPath;
export const GO_TO_CANCEL_REQUEST = cancelRequest.staticPath;
export const GO_TO_CANCEL_CONFIG = cancelConfig.staticPath;
export const GO_TO_CANCEL_REQUEST_ITEMS = cancelRequestItems.staticPath;
export const GO_TO_VINCULUM_ORDERS = vinculumOrders.staticPath;
export const GO_TO_SIGNUP_SCREEN = signupScreen.staticPath;
export const GO_TO_USER_LIST = userlistScreen.staticPath;
export const GO_TO_REFUND_LIST = refundList.staticPath;
export const GO_TO_REFUND_PAYMENTS_LIST = refundPaymentList.staticPath;
export const GO_TO_REFUND_PAYMENT_ITEM = refundPaymentItem.staticPath;
export const GO_TO_REFUND_ITEM = refundItem.staticPath;
export const GO_TO_CREATE_REFUND = createRefund.staticPath;
// export const GO_TO_OLDER_REIMBURSEMENT = 'http://zxcvbnm4378456.dentalkart.com/customerCare/reimbursement'
