import axios from "axios";
import BrowserPersistence from '../helpers/browser_persistence';

const storage = new BrowserPersistence();
const token = storage.getItem('token') || null;

const client = axios.create({
    baseURL: "/" ,
    headers:{
        'authorization': `Token ${token}`,
        'Content-Type': 'application/json'
    }
  });

  export default client;
