import { lazy } from "react";

export const ErroScreen404 = lazy(() => import("./errorScreen"));
export const HomePage = lazy(() => import("./homePage"));
export const ReturnScreen = lazy(() => import('./returnModule/returnScreen'));
export const ReturnItemsRequest = lazy(() => import('./returnModule/returnItemsRequest'));
export const ReturnApproved = lazy(() => import('./returnModule/returnApproved'));
export const ReturnConfiguration = lazy(() => import('./returnModule/returnConfiguration'));
export const ReturnCreate = lazy(() => import('./returnModule/returnCreate'));
export const ApprovedProducts = lazy(() => import('./returnModule/returnApprovedProducts'));
export const SaleTemplate = lazy(() => import("./saleModule/saleTemplate"));
export const SaleComponent = lazy(() => import("./saleModule/saleComponent"));
export const CallCustomer = lazy(() => import("./call/ui/outgoingCall"));
export const Reimbursement = lazy(() => import("./reimbursement"));
export const ReimbursementConfig = lazy(() => import("./reimbursementConfig"));
export const CallConfig = lazy(() => import("./callConfig"));
export const CreateForm = lazy(() => import("./formModule/createForm"));
export const FormViewComponent = lazy(() => import("./formModule/formViewComponent"));
export const FormResponse = lazy(() => import("./formModule/formResponse"));
export const FormResponseById = lazy(() => import("./formModule/formResponseById"))
export const WhatsappApiScreen = lazy(() => import("./whatsappApi"));
export const CancelOrderScreen = lazy(() => import('./order-cancel/cancelOrder'));
export const CancelRequestScreen = lazy(() => import('./order-cancel/cancelRequest'));
export const CancelConfig = lazy(() => import('./order-cancel/cancelConfig'));
export const CancelReuqestItems = lazy(() => import('./order-cancel/cancelRequest/cancelReuqestItems'))
export const AuthScreen = lazy(() => import("./authScreen"));
export const Orders = lazy(() => import("./ordersModule"));
export const UpdateVinOrders = lazy(() => import("./updateVinOrders"));
export const SignupScreen = lazy(() => import("./userModule/signupScreen"));
export const UserlistScreen = lazy(() => import("./userModule/userlistScreen"));
export const RefundsList = lazy(() => import("./refunds/refundsList"));
export const RefundsPaymentList = lazy (() => import("./refunds/refundsPaymentList"));
export const RefundItem = lazy(() => import("./refunds/refundItem"));
export const RefundPaymentItem = lazy(() => import("./refunds/refundPaymentItem"));
export const CreateRefund = lazy(() => import("./refunds/createRefund")) 