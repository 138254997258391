import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import PaymentsIcon from '@mui/icons-material/Payments';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FeedIcon from '@mui/icons-material/Feed';
import ViewListIcon from '@mui/icons-material/ViewList';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import BackspaceIcon from '@mui/icons-material/Backspace';
import PriceChangeIcon from '@mui/icons-material/PriceChange';

const drawerConfig = [
    {
        title: 'Home',
        path: 'GO_TO_HOMEPAGE',
        permission:['return','sale','call', 'vinorder','reimbursement','form','whatsapp','cancellation', 'admin','order','vinorder'],
        leftIcon: <HomeIcon />,
    },
    {
        title:'Call',
        leftIcon: <CallIcon />,
        permission:['call','admin'],
        children:[
            {
                title: 'Call Customer',
                path: 'GO_TO_CALL_CUSTOMER',
            },
            {
                title:'Config',
                path:'GO_TO_CALL_CONFIG',
            }
        ],
    },
    {
        title: 'Return Module',
        leftIcon: <KeyboardReturnIcon />,
        permission:['return','admin'],
        children:[
            {
                title: 'Return',
                path: 'GO_TO_RETURN',
            },
            {
                title:'Return Approved',
                path:'GO_TO_RETURN_APPROVED',
            },
            {
                title:'Return Create',
                path:'GO_TO_RETURN_CREATE',
            },
            {
                title:'Return Config',
                path: 'GO_TO_RETURN_CONFIGURATION',
            }
        ],

    },
    {
        title:'Sale Template',
        path:'GO_TO_SALE_TEMPLATE',
        permission:['sale','admin'],
        leftIcon: <TrendingDownIcon />,
    },
    {
        title:'Reimbursement',
        permission:['reimbursement','admin'],
        leftIcon: <PaymentsIcon />,
        children:[
            {
                title:'Refunds',
                path:'GO_TO_REIMBURSEMENT'
            },
            {
                title:'Config',
                path:'GO_TO_REIMBURSEMENT_CONFIG'
            },
            {
                title:'Older Version',

            }
        ]
    },
    {
        title:'Generic Form',
        permission:['form','admin'],
        leftIcon: <FeedIcon />,
        children:[
            {
                title: 'Create Form',
                path:'GO_TO_CREATE_FORM',
            },
            {
                title:'View Response',
                path:'GO_TO_FORM_RESPONSE',
            }
        ],
    },
    {
        title:'Whatsapp Api',
        path:'GO_TO_WHATSAPP_API',
        permission:['whatsapp','admin'],
        leftIcon: <WhatsAppIcon />,
    },
    {
        title:'Orders',
        path:'GO_TO_ORDERS',
        permission:['order','admin'],
        leftIcon: <ViewListIcon />,
    },
    {
        title:'Vin Orders',
        permission:['vinorder','admin'],
        path:'GO_TO_VINCULUM_ORDERS',
        leftIcon: <CallToActionIcon />
    },
    {
        title:'Order Cancel',
        leftIcon: <BackspaceIcon />,
        permission:['cancellation','admin'],
        children:[
            {
                title: 'Cancel Order',
                path:'GO_TO_CANCEL_ORDER',
            },
            {
                title:'View Cancel Request',
                path:'GO_TO_CANCEL_REQUEST',
            },
            {
                title:'Configuration',
                path:'GO_TO_CANCEL_CONFIG',
            }
        ],
    },
    {
        title:'Refund',
        leftIcon: <PriceChangeIcon />,
        permission:['refund','admin'],
        children:[
            {
                title: 'RazorPay Refund List',
                path:'GO_TO_REFUND_LIST',
            },
            {
                title: 'RazorPay Refund Payments',
                path:'GO_TO_REFUND_PAYMENTS_LIST',
            },
            {
                title: 'Create Refund',
                path:'GO_TO_CREATE_REFUND',
            }
        ],
    },
    {
        title:'User Management',
        leftIcon: <PeopleIcon />,
        permission:['SUPER_ADMIN'],
        children:[
            {
                title: 'User List',
                path:'GO_TO_USER_LIST'
            },
            {
                title: 'User Signup',
                path:'GO_TO_SIGNUP_SCREEN'
            }
        ]
    },
    {
        title: 'Logout',
        path: 'GO_TO_LOGIN_SCREEN',
        permission:['return','sale','call', 'vinorder','reimbursement','form','whatsapp','cancellation', 'admin','order','vinorder'],
        leftIcon: <LogoutIcon />,
    },


];

export default drawerConfig;
