
import React, {Suspense, useState} from 'react';
import Style from './app.module.scss';
import ReactDOM from 'react-dom';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate
  } from "react-router-dom";
import * as RoutesConfig from '@config/routes';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

import drawerConfig from '@config/drawerConfig';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {primaryColor, secondaryColor} from '@config/environment';
import {AppContext, BrowserPersistence, getLoginStatus, routeRedirect, client, SnackBar, FullScreenLoader, AppDrawer, Header} from '@react-projects/shared';

const graphqlClient = new ApolloClient({
	fetchOptions: {
    	credentials: "include"
  	},
  	headers: {
    	"x-api-key" : 'da2-b7vqajjrfbgbvjf4fbesbavuhq'
  	},
  	uri: 'https://api-apollo.dentalkart.com/graphql',
});

const storage = new BrowserPersistence();

const theme = createTheme({
    palette: {
        primary: {
            main: primaryColor,
        },
        secondary: {
            main: secondaryColor,
        }
    },
});



const App = _ => {
    const [snackBarState, setSnackBarState] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(getLoginStatus());
    // const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [snackbarConfig, setSnackbarConfig] = useState({severity:'', message: ''});
	const [sideBarActiveLink, setSideBarActiveLink] = useState('Home');
	const [userInfo, setUserInfo] = useState('');
	const [username, setUsername] = useState();
	const [user, setUser] = useState({name:''});

    const toggleSnackBar = ({severity, message, visibility}) => {
        setSnackbarConfig({severity, message});
        setSnackBarState(visibility);
		console.log("ddhj")
    }

	const handleUser = (name) => {
		// alert("ddj")
		setUser({name})
	}

    const logout = _=> {
        storage.removeItem('access_token');
		storage.removeItem('access');
		storage.removeItem('username');
        setIsLoggedIn(false)
    }

	const contextValues = {
		routes: RoutesConfig,
        isLoggedIn,
        client,
        toggleSnackBar,
        sideBarActiveLink,
        setSideBarActiveLink,
		userInfo,
		username,
		setUsername,
		setUserInfo,
		handleUser,
		user,
        logout
    }

	const checkAccess = () => {

		let access = storage.getItem('access') || null;
		
		if(storage.getItem('access') !== null){
		 	access = storage.getItem('access'); 
		}
		

		if(access?.length > 0 && isLoggedIn) return true;

		return false;


		// if(isLoggedIn) return true; 
	}

	return (
	    <ThemeProvider theme={theme}>
            <AppContext.Provider value={contextValues}>
				<ApolloProvider client={graphqlClient}>
	                <Router>
						{isLoggedIn &&<Header />}
	                    <div className={[ Style.app_wrapper, Style.flex_class, Style.fc_jc_c, Style.fc_al_c].join(' ')}>
							{checkAccess() && <div className={Style.darwer_section}><AppDrawer appContext={AppContext} drawerConfig={drawerConfig} /></div>}
							<div className={Style.grid_scection}>
								<SnackBar snackBarVisibity={snackBarState} message={snackbarConfig.message} severity={snackbarConfig.severity} toggleSnackBar={setSnackBarState} />
		                        <Suspense fallback={<FullScreenLoader visiblity={true} />}>
		                            <Routes>
		                                {RoutesConfig.screens.map((route, key) =>
		                                    <Route exact={route.exact} path={route.path}
		                                        key={`route-${route.name}-${'key'}`}
		                                        element={
		                                            routeRedirect({screenType: route.type, isLoggedIn}) ? (
		                                                <Navigate to={{ pathname: route.redirectPath }} />
		                                            ) : (
		                                                <route.component {...route} />
		                                            )
		                                        }
		                                    />
		                                )}
		                            </Routes>
		                        </Suspense>
							</div>
	                    </div>
	                </Router>
				</ApolloProvider>
            </AppContext.Provider>
        </ThemeProvider>
	)
}

ReactDOM.render(
    <App />,
    document.getElementById('root')
);
