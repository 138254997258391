import React from "react";
import Style from "./loader.module.scss";
import CircularProgress from '@mui/material/CircularProgress';


export const FullScreenLoader = () => (
    <div className={Style.full_screen_wrapper}>
        <div className={Style.circle_wrapper}>
            <CircularProgress className={Style.circle} />
        </div>
    </div>
);

export const ComponentLoader = () => (
    <div className={Style.componentLoader_wrapper}>
        <div className={Style.componentLoader_circle_wrapper}>
            <CircularProgress className={Style.componentLoader_circle} />
        </div>
    </div>
);

export const FullLengthLoader = () => (
    <div className={Style.f_componentLoader_wrapper}>
        <div className={Style.f_componentLoader_circle_wrapper}>
            <CircularProgress className={Style.f_componentLoader_circle} />
        </div>
    </div>
)
