const routeRedirect = ({screenType, isLoggedIn}) => {
    const redirect = {
        'protected': isLoggedIn ? false : true,
        'public': false,
        'auth': isLoggedIn ? true : false,
        'default': false
    }
    
    return redirect[screenType] || redirect['default'];
}

export default routeRedirect;
