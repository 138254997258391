import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const SnackBar = ({severity, toggleSnackBar, snackBarVisibity, message}) => {
    return (
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackBarVisibity} autoHideDuration={6000} onClose={() => toggleSnackBar(false)}>
            <Alert elevation={6} variant="filled" onClose={() => toggleSnackBar(false)} severity={severity}>
              {message}
            </Alert>
        </Snackbar>
    )
}

export default SnackBar;